import React, { useState, useRef } from "react";
import _ from "lodash";
import { concatClassNames as cn, accessibleOnClick } from "@system42/core";
import { useClickOutside } from "../../helpers";
import Label from "../Label";

import * as styles from "./styles.module.css";

import iconExpandMore from "./expand-more-icon.svg";
import iconExpandLess from "./expand-less-icon.svg";

export default function FormFieldSelect(props) {
  const {
    className,
    below,
    above,
    errorMessage,
    options,
    onChange,
    placeholder,
    label,
    initialOption,
    fullWidth,
  } = props;

  const [selectedOption, setSelectedOption] = useState(
    options.filter((opt) => opt.value === initialOption?.value)[0] ?? null
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const refSelectMenu = useRef();
  const id = _.uniqueId("input");

  function handleClickSelectOption(option) {
    setSelectedOption(option);
    if (typeof onChange === "function") {
      onChange(option);
    }
    setMenuOpen(false);
  }

  function handleClickLanguageToggle() {
    setMenuOpen(!menuOpen);
  }

  function handleClickOutside() {
    setMenuOpen(false);
  }

  useClickOutside(refSelectMenu, handleClickOutside);

  return (
    <div className={cn(className, styles.root)} ref={refSelectMenu}>
      <Label htmlFor={id}>{label}</Label>
      {above}
      <div
        className={cn(styles.selectContainer, fullWidth && styles.fullWidth)}
        {...accessibleOnClick(handleClickLanguageToggle)}
      >
        <span
          className={selectedOption === null ? styles.placeholder : undefined}
        >
          {selectedOption === null ? placeholder : selectedOption.text}
        </span>
        {menuOpen ? (
          <img src={iconExpandLess} alt={"Expand Less"} />
        ) : (
          <img src={iconExpandMore} alt={"Expand More"} />
        )}
      </div>
      {menuOpen && (
        <div className={styles.selectOptions}>
          {options?.map((option, i) => (
            <div
              key={i}
              className={styles.selectOption}
              {...accessibleOnClick(() => handleClickSelectOption(option))}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
      {errorMessage}
      {below}
    </div>
  );
}
