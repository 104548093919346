// extracted by mini-css-extract-plugin
export var form = "styles-module--form--50064";
export var formRow = "styles-module--formRow--5633d";
export var formRow__2col = "styles-module--formRow__2col--589ec";
export var formSubmit = "styles-module--formSubmit--f21aa";
export var intro = "styles-module--intro--7e91b";
export var previewImage = "styles-module--previewImage--9307e";
export var registerFree = "styles-module--registerFree--82e0e";
export var shareButton = "styles-module--shareButton--e6f23";
export var splitLayout = "styles-module--splitLayout--72640";
export var splitLayoutCol = "styles-module--splitLayoutCol--0145a";
export var splitLayoutCol_flexColumn = "styles-module--splitLayoutCol_flexColumn--49069";
export var splitLayoutLeft = "styles-module--splitLayoutLeft--872e8";
export var splitLayoutRight = "styles-module--splitLayoutRight--606b3";
export var title = "styles-module--title--9493a";
export var unorderedList = "styles-module--unorderedList--7fb87";
export var whatToExpect = "styles-module--whatToExpect--e4245";