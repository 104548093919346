import React, { useState } from "react";
import { navigate } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@system42/core";
import Layout from "../../layouts/layout";
import FormFieldSelect from "../../components/FormFieldSelect";
import * as styles from "./styles.module.css";

import imgWeeklyProductDemo from "./weekly-product-demo.jpg";
import {
  ButtonPrimaryLarge,
  Headline4Sans,
  FormFieldText,
  Headline2Sans,
} from "@simplease/system42-theme-userbrain";

export default function Index() {
  const title = "Live Webinars on User Testing";

  const [formFirstName, setFormFirstName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formCompanyName, setFormCompanyName] = useState("");
  const [formNumberOfEmployees, setFormNumberOfEmployees] = useState(null);

  function handleClickShare() {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        title
      )}&via=userbrain`
    );
  }

  function handleSubmitForm(e) {
    e.preventDefault();
    navigate("/webinar-registration/confirmation"); //Hmm relative URL should be possible?
  }

  return (
    <Layout
      title={title}
      metaDescription={
        "We know it can be difficult to find the testing site that’s best for your business. That’s why we’re hosting a live webinar to show you how Userbrain can help you improve your site."
      }
    >
      <article className={styles.splitLayout}>
        <div className={styles.splitLayoutLeft}>
          <div
            className={cn(
              styles.splitLayoutCol,
              styles.splitLayoutCol_flexColumn
            )}
          >
            <Headline2Sans className={styles.title} customTag={"h1"}>
              Webinar-Registration
            </Headline2Sans>
            <img
              className={styles.previewImage}
              src={imgWeeklyProductDemo}
              alt="Live User Testing Webinars"
            />
            <button
              className={styles.shareButton}
              onClick={handleClickShare}
              aria-label={"Share"}
            />
          </div>
        </div>
        <div className={styles.splitLayoutRight}>
          <div className={styles.splitLayoutCol}>
            <p className={styles.intro}>
              We know it can be difficult to find the testing site that’s best
              for your business. That’s why we’re hosting a live webinar to show
              you how Userbrain can help you improve your site.
            </p>
            <Headline4Sans customTag={"h2"} className={styles.whatToExpect}>
              What to expect
            </Headline4Sans>
            <ul className={styles.unorderedList}>
              <li>
                Live example of how the testing process works with one of our
                Userbrain testers.
              </li>
              <li>
                Getting to know our tester pool and the demographics you will be
                able to choose from.
              </li>
              <li>
                Walkthrough of setting up and effective test and screener.
              </li>
              <li>Q&A at the end to answer all of your questions.</li>
            </ul>
            <Headline4Sans customTag={"h2"} className={styles.registerFree}>
              Register for free
            </Headline4Sans>
            <p>Fill out your details below to join us at the next Webinar.</p>
            <form className={styles.form} onSubmit={handleSubmitForm}>
              <div className={cn(styles.formRow, styles.formRow__2col)}>
                <FormFieldText
                  label={"First name"}
                  placeholder={"Enter first name"}
                  value={formFirstName}
                  onChange={setFormFirstName}
                />
                <FormFieldText
                  label={"Last name"}
                  placeholder={"Enter last name"}
                  value={formLastName}
                  onChange={setFormLastName}
                />
              </div>
              <FormFieldText
                className={styles.formRow}
                label={"Email address"}
                placeholder={"Enter email"}
                value={formEmail}
                onChange={setFormEmail}
              />
              <FormFieldText
                className={styles.formRow}
                label={"Company name"}
                placeholder={"Enter company name"}
                value={formCompanyName}
                onChange={setFormCompanyName}
              />
              <FormFieldSelect
                className={styles.formRow}
                label={"Number of Employees"}
                placeholder={"Select Number of Employees"}
                onChange={setFormNumberOfEmployees}
                initialOption={formNumberOfEmployees}
                fullWidth
                options={[
                  {
                    value: 0,
                    text: "Only me",
                  },
                  {
                    value: 1,
                    text: "2-5",
                  },
                  {
                    value: 2,
                    text: "5-10",
                  },
                  {
                    value: 3,
                    text: "10+",
                  },
                ]}
              />
              <ButtonPrimaryLarge type={"submit"} className={styles.formSubmit}>
                Register
              </ButtonPrimaryLarge>
            </form>
          </div>
        </div>
      </article>
    </Layout>
  );
}
