import React from "react";
import { concatClassNames as cn } from "@system42/core";
import { extractContainerProps } from "../../helpers";

import * as styles from "./styles.module.css";

export default function Label(props) {
  const { children, className, htmlFor } = props;

  return (
    <label
      {...extractContainerProps(props)}
      className={cn(className, styles.root)}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}
